// import node_modules
import * as React from 'react';
import { Heading, LinkButton, Spacer, Text } from '@storylinerlabs/design';
import { useTranslation } from 'react-i18next';
import { useImmerAtom } from 'jotai/immer';

// import types
import type { NextPage } from 'next';

// import component
import Column from '../components/atoms/Column';
import LinkComponent from '../components/atoms/LinkComponent';
import MainLayout from '../components/layouts/MainLayout';
import SEO from '../components/meta/SEO';
import Section from '../components/atoms/Section';

// import modules
import { localeAtom } from '../modules/jotai';

// define page
const NotFoundPage: NextPage = () => {
  // init hooks
  const { t } = useTranslation('not-found');
  const [locale] = useImmerAtom(localeAtom);

  // init vars
  const hrefLang = '*';

  // init render
  return (
    <MainLayout>
      <SEO
        description={t('seo.description')}
        hrefLang={hrefLang}
        image={t('seo.image')}
        title={t('seo.title')}
      />
      <Section>
        {/* Navbar */}
        <Spacer height="64px" />
        <Spacer height="50px" />
      </Section>
      <Section>
        <Column>
          <Heading alignment="center">{t('hero.title')}</Heading>
          <Text alignment="center" size="large">
            {t('hero.description')}
          </Text>

          <div className="button-container">
            <LinkComponent href={locale === 'de' ? '/de/' : '/'}>
              <LinkButton color="naked" size="large" text={t('hero.link.label')} textIsPrimary />
            </LinkComponent>
          </div>
        </Column>
      </Section>
      <Section>
        <Spacer height="50px" />
      </Section>
    </MainLayout>
  );
};

export default NotFoundPage;
